import Head from "next/head";
import { GetServerSideProps, GetServerSidePropsContext, NextPage } from "next";

//Features
import LoginContainer from "@/features/kumu/flow/login";
import { redirectIfAuthenticated } from "@/utils/session";
import { serverFetchService } from "@/services/fetch";

// Utils
import { KUMU_URLS } from "@/utils/urls/kumu";

type Props = {
  googleUrl: string;
  emailConfirmed: string;
};

const LoginKumuPage: NextPage<Props> = ({ googleUrl, emailConfirmed }) => {
  return (
    <>
      <Head>
        <title>Kumu</title>
      </Head>
      <LoginContainer googleUrl={googleUrl} emailConfirmed={emailConfirmed} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = redirectIfAuthenticated(
  async (context: GetServerSidePropsContext) => {
    const { clientId, username, code } = context.query;
    let accountConfirmedData: AccountConfirmedData | null = null;

    const loginMethods: { google: { url: string } } = await serverFetchService(
      "/auth/login-methods"
    );

    if (clientId && username && code) {
      accountConfirmedData = await serverFetchService(
        `/auth/account-confirmation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            confirmationCode: code,
            username: username,
            clientId: clientId,
          }),
        }
      );
    }

    return {
      props: {
        googleUrl: loginMethods.google.url,
        emailConfirmed: accountConfirmedData?.email ?? "",
      },
    };
  }
);

export default LoginKumuPage;
