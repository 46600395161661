import { FunctionComponent } from "react";
import BaseLink, { LinkProps } from "next/link"; // Renaming over Link from next
import React from "react";
import { classNames } from "@/utils/classNames";
import { useRouter } from "next/router";

type Props = {
  activeClassName?: string;
  activePathname?: string;
  className?: string;
  target?: string;
  isAnchorTag?: boolean;
  children?: React.ReactNode;
};

const Link: FunctionComponent<Props & LinkProps> = ({
  children,
  activeClassName,
  activePathname,
  className,
  href,
  target,
  isAnchorTag,
  prefetch = false, // default value for prefetch
  ...restOfProps
}) => {
  const router = useRouter();

  if (isAnchorTag)
    return (
      <a
        role="link"
        className={classNames("cursor-pointer", className)}
        target={target}
        href={isAnchorTag ? href.toString() : undefined}
      >
        {children}
      </a>
    );

  return (
    <BaseLink
      href={href}
      {...restOfProps}
      role="link"
      className={classNames(
        className,
        router.pathname === activePathname ? activeClassName : null
      )}
      target={target}
    >
      {children}
    </BaseLink>
  );
};

export { Link };
