import React, { FunctionComponent } from "react";

type Props = {
  className?: string;
  onSubmit: (e: React.SyntheticEvent) => void;
  children: React.ReactNode;
};

const Form: FunctionComponent<Props> = ({ children, className, onSubmit }) => (
  <form className={className} onSubmit={onSubmit}>
    {children}
  </form>
);

export { Form };
