import useTranslation from "next-translate/useTranslation";
import { FunctionComponent } from "react";
// Components
import KumuEscapePath from "@/components/layout/kumu/escape-path";
import FlowFormsLayout from "@/components/layout/kumu/flow-forms";
import KumuFlowLayout from "@/components/layout/kumu/flow-layout";
import useViewportSize from "@/utils/hooks/useViewportSize";
import { KUMU_URLS } from "@/utils/urls/kumu";
import { LoginKumuForm } from "./login-form";

type Props = {
  googleUrl: string;
  emailConfirmed: string;
};

const LoginContainer: FunctionComponent<Props> = ({
  googleUrl,
  emailConfirmed,
}) => {
  const { t } = useTranslation("common");

  const { height: viewportHeight } = useViewportSize();

  return (
    <KumuFlowLayout
      viewportHeight={viewportHeight}
      srcFormImage="/images/kumu/flow/personal-details-img.webp"
      srcBackgroundBlurImage="/images/kumu/flow/personal-details-bg.webp"
    >
      <FlowFormsLayout
        title={t("login.loginkumu")}
        description={t("login.continueLogin")}
      >
        <div className="px-10 mt-8">
          <LoginKumuForm
            urls={{ googleUrl: googleUrl }}
            emailConfirmed={emailConfirmed}
          />
        </div>
      </FlowFormsLayout>
      <KumuEscapePath
        link={KUMU_URLS.PAGES.B2C.FLOW.MEMBER.SIGN_UP}
        className="mt-2"
        label={t("common:login.notmember")}
        linkLabel={t("common:login.continueSignUp")}
      />
    </KumuFlowLayout>
  );
};

export default LoginContainer;
