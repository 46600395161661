import React, { FunctionComponent, useContext, useState } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import * as Sentry from "@sentry/nextjs";

// Context
import { useRouter } from "next/router";
import UserContext from "@/context/user";
import { useAuth } from "@/context/auth/hooks";

// Components
import { Form } from "@/components/common/form";
import { Link } from "@/components/common/link";
import { Input } from "@/components/common/input";
import { Label } from "@/components/common/label";
import { GetIcon } from "@/components/common/icon";
import { Snackbar } from "@/components/common/snackbar";
import KumuButtonComponent from "@/components/layout/kumu/button-component";

// Utils
import { SIXTY_URLS } from "@/utils/urls";
import { KUMU_URLS } from "@/utils/urls/kumu";
import { classNames } from "@/utils/classNames";
import { useToggle } from "@/utils/hooks/useToggle";
import { clientFetchService } from "@/services/fetch";
import { EXPIRE_IN_A_YEAR, createCookie } from "@/utils/cookies";
import { usePreviousPathContext } from "@/context/previouspath";
import { getTestIdGenerator } from "@/utils/test";
import { sendGAEvent } from "@next/third-parties/google";

const GoogleLoginButton: FunctionComponent<{
  onClick?: () => void;
  creatorLogin?: boolean;
  isLoading: boolean;
}> = ({ onClick, isLoading, creatorLogin = false }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="cursor-pointer border border-[#65B0B7] rounded-full flex items-center justify-center h-[50px] w-full hover:shadow-lg pressed:shadow-lg  transition-shadow"
    >
      <Image
        src="/google_logo.svg"
        alt="Google Logo"
        width={23}
        height={23}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
      <Label
        textColor={creatorLogin ? "light-4" : "[#014157]"}
        className={classNames(
          "ml-4",
          creatorLogin ? "hover:text-[#33878E]" : null
        )}
        fontWeight="medium"
        fontSize="base"
      >
        {isLoading ? (
          <GetIcon
            className={classNames(
              `animate-spin m-auto fill-current text-[#65B0B7] w-[27px] h-[26px]`
            )}
            iconName="loading"
          />
        ) : (
          t("common:login.logwithgoogle")
        )}
      </Label>
    </div>
  );
};

const testIdGen = getTestIdGenerator("LoginComponent");

const testIds = {
  loginButton: testIdGen("LoginButton"),
};

const LoginKumuForm: FunctionComponent<{
  urls?: { googleUrl: string };
  modifier?: "dark" | "light";
  creatorLogin?: boolean;
  emailConfirmed?: string;
}> = ({ urls, modifier = "light", creatorLogin, emailConfirmed }) => {
  const { previousPath } = usePreviousPathContext();
  const { t } = useTranslation();
  const { user, setUser, resetUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isLoadingSocial, setIsLoadingSocial] = useToggle(false);

  const [email, setEmail] = useState(emailConfirmed ?? "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [validUser, setValidUser] = useState(true);
  const modifierType = {
    dark: modifier === "dark",
    light: modifier === "light",
  };
  const router = useRouter();
  const { logIn, logOut, socialLoginCreateCreator } = useAuth();
  const handleTogglePassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (passwordType === "password") setPasswordType("text");
    if (passwordType === "text") setPasswordType("password");
  };

  const setUserError = async () => {
    setValidUser(false);
    await logOut().then(() => {
      resetUser();
    });
  };

  React.useEffect(() => {
    createCookie(
      "loginPageUsed",
      creatorLogin ? "creator" : "user",
      EXPIRE_IN_A_YEAR
    );
  }, [creatorLogin]);

  const userController = React.useMemo(() => {
    return {
      endUserNotOnboarded: () =>
        router.push(KUMU_URLS.PAGES.B2C.FLOW.MEMBER.PERSONAL_DETAILS),
      endUserOnboarded: () => {
        const productId = localStorage.getItem("digitalProductIdReferrer");
        if (!productId) {
          router.push(previousPath ? previousPath : KUMU_URLS.PAGES.B2C.MAIN);
        } else {
          localStorage.removeItem("digitalProductIdReferrer");
          router.push(
            KUMU_URLS.PAGES.B2C.DIGITAL_PRODUCTS.DIGITAL_PRODUCT_BY_ID(
              productId
            )
          );
        }
      },
      creatorNotOnboarded: () =>
        router.push(KUMU_URLS.PAGES.B2C.FLOW.MEMBER.PERSONAL_DETAILS),
      creatorOnboarded: () =>
        router.push(previousPath ? previousPath : KUMU_URLS.PAGES.B2B.MAIN),
    };
  }, [router]);

  const isEmailConfirmed = !!emailConfirmed;

  const handleLogInSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLoading();
    logIn({ email, password })
      .then(async () => {
        setIsLoading();
        // NOTE: Set user info for Sentry logs
        Sentry.setUser({ id: email });
        sendGAEvent({ event: "loggedUser", value: "User logged in" });
        await afterLoginProcess();
      })
      .catch((err) => {
        setIsLoading();
        setError(err.message);
      });
  };

  const handleGoogleLogin = () => {
    setIsLoadingSocial();
    const windowRef = window.open(
      urls?.googleUrl,
      "Google Login",
      "width=800,height=600"
    );
    const windowInterval = setInterval(async () => {
      if (windowRef?.closed) {
        clearInterval(windowInterval);
        await afterLoginProcess();
        setIsLoadingSocial();
        // window.location.reload();
      }
    }, 1500);
  };

  const afterLoginProcess = React.useCallback(async () => {
    let createdCreator = null;
    const apiUser: User = await clientFetchService(SIXTY_URLS.API.USERS, {
      method: "GET",
    });

    const isCreator = apiUser.creator || createdCreator;

    if (apiUser) {
      const userOnboarded = apiUser.isOnboardingCompleted ?? false;
      setUser({ ...apiUser });

      const userType =
        !isCreator && !apiUser.permissions?.includes("ADMIN") && userOnboarded
          ? "endUserOnboarded"
          : !isCreator &&
              !apiUser.permissions?.includes("ADMIN") &&
              !userOnboarded
            ? "endUserNotOnboarded"
            : isCreator &&
                !apiUser.permissions?.includes("ADMIN") &&
                userOnboarded
              ? "creatorOnboarded"
              : isCreator &&
                  !apiUser.permissions?.includes("ADMIN") &&
                  !userOnboarded
                ? "creatorNotOnboarded"
                : "";
      /* const loginType = creatorLogin ? "creatorLogin" : "notCreatorLogin"; */
      const handleUser = (userType: string) => {
        const currentKey = `${userType}` as keyof typeof userController;
        const currentFunction = userController[currentKey];
        currentFunction();
      };
      handleUser(userType);
    }
  }, [creatorLogin, setUser, userController]);

  return (
    <>
      <Form className="w-full" onSubmit={handleLogInSubmit}>
        {isEmailConfirmed && (
          <Snackbar
            className="mb-[16px]"
            message={t("common:login.validationSuccess")}
          />
        )}
        <label
          htmlFor="email"
          className="text-graphite text-base font-semibold"
        >
          Email
        </label>
        <Input
          className={classNames()}
          modifier={modifierType.dark ? "dark" : undefined}
          aria-label="Email"
          name="email"
          type="email"
          required
          autoComplete="username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("common:login.emailaddress")}
          disabled={isLoading || isLoadingSocial}
        />
        <div className="relative mt-4">
          <label
            htmlFor="password"
            className="text-graphite text-base font-semibold"
          >
            {t("common:login.password")}
          </label>
          <div className="relative">
            <Input
              className={classNames()}
              modifier={modifierType.dark ? "dark" : undefined}
              aria-label="Password"
              aria-required={true}
              autoCapitalize="off"
              autoComplete="current-password"
              autoCorrect="off"
              name="password"
              type={passwordType}
              aria-autocomplete="list"
              placeholder={t("common:login.typePassword")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading || isLoadingSocial}
            />
            <button
              className="absolute right-4 top-[50%] translate-y-[-50%] text-[#A1A4B2]"
              type="button"
              onClick={handleTogglePassword}
            >
              {passwordType === "password" ? (
                <GetIcon iconName="hide-password" className="fill-current" />
              ) : (
                <GetIcon iconName="show-password" className="fill-current" />
              )}
            </button>
          </div>
        </div>
        {error && error !== "" ? (
          <Label
            className="my-2"
            fontSize="xs"
            textAlignment="left"
            textColor="[#B00020]"
          >
            {error}
          </Label>
        ) : null}
        <Link
          className="block w-[fit-content] mt-6"
          href={KUMU_URLS.PAGES.B2C.FLOW.RESTORE_PASSWORD}
        >
          <Label
            fontSize="base"
            textAlignment="left"
            textColor="graphite"
            fontWeight="semibold"
            className={classNames("underline")}
          >
            {t("common:login.forgot")}
          </Label>
        </Link>
        <KumuButtonComponent
          className="w-full mt-8"
          type="submit"
          theme="green"
          data-testid={testIds.loginButton}
        >
          {isLoading ? (
            <GetIcon
              className="animate-spin m-auto fill-current text-white w-[27px] h-[26px]"
              iconName="loading"
            />
          ) : (
            t("common:login.login")
          )}
        </KumuButtonComponent>
        {validUser === false ? (
          <Label
            fontSize="sm"
            textAlignment="center"
            textColor="[#E8495C]"
            className="mt-2"
          >
            {t("common:signUp.userProblem")}
          </Label>
        ) : null}
        {/* <div className="flex flex-row mt-[24px] justify-around items-center">
          <svg
            width={80}
            height={2}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#1A606F" d="M80 1.123H0" />
          </svg>
          <Label
            fontSize="xs"
            className="mx-3"
            textColor={modifierType.light ? "[#014157]" : "[#1A606F]"}
          >
            {t("common:login.continuewith")}
          </Label>
          <svg
            width={80}
            height={2}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#1A606F" d="M80 1.123H0" />
          </svg>
        </div>
        <div className="flex justify-around mt-4">
          <GoogleLoginButton
            onClick={handleGoogleLogin}
            creatorLogin={creatorLogin}
            isLoading={isLoadingSocial}
          />
        </div> */}
      </Form>
    </>
  );
};
export { LoginKumuForm };
