// Services
import { clientFetchService } from "@/services/fetch";

// Utils
import { SIXTY_URLS } from "@/utils/urls";
import {
  createCookie,
  deleteCookie,
  deleteCookieInAllDomains,
  EXPIRE_IN_A_YEAR,
  getCookie,
} from "@/utils/cookies";
import { useContext } from "react";
import UserContext from "../user";

//

type Error = {
  message: string;
};

const useAuth = () => {
  // need more handling of redirect to ref in case of loging/signup from a creator or also if token just expired after a long time
  const { resetUser } = useContext(UserContext);

  const logIn = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<Session | Error> => {
    return clientFetchService<Session>(SIXTY_URLS.API.AUTH.LOG_IN, {
      method: "POST",
      body: JSON.stringify({ email, password }),
    }).then((r: Session) => {
      deleteCookieInAllDomains("accessToken");

      if (r.statusCode && r.statusCode !== 200) {
        throw new Error(r.message);
      }

      createCookie("accessToken", r.accessToken.jwtToken, EXPIRE_IN_A_YEAR);
      return r;
    });
  };

  const logOut = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        try {
          // nested try/catch so we don't fail logout due to caches DOM API
          caches
            .keys()
            .then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            })
            .catch((e) => console.log(e));
        } catch (e) {
          console.log("[ERROR] error clearing cache ", e);
        }
        resetUser();
        deleteCookieInAllDomains("accessToken");
        deleteCookieInAllDomains("email");
        resolve();
      } catch (e) {
        reject();
      }
    });
  };

  const signUp = ({
    email,
    password,
    userName,
    optedIn,
    rToken,
  }: {
    email: string;
    password: string;
    userName?: string;
    optedIn?: boolean;
    rToken?: string;
  }) => {
    return clientFetchService(SIXTY_URLS.API.USERS, {
      method: "POST",
      body: JSON.stringify({ email, password, userName, optedIn, rToken }),
    });
  };

  const initiateRestore = ({ email }: { email: string }) => {
    return clientFetchService(SIXTY_URLS.API.AUTH.INIT_RESTORE, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
  };

  const completeRestore = ({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }) => {
    return clientFetchService(SIXTY_URLS.API.AUTH.COMPLETE_RESTORE, {
      method: "POST",
      body: JSON.stringify({
        email,
        code,
        password,
      }),
    });
  };

  const socialLogin = (
    accessToken: string,
    idToken: string,
    isCreator: boolean
  ): Promise<Session | Error> => {
    return clientFetchService<Session>(SIXTY_URLS.API.AUTH.SOCIAL, {
      method: "POST",
      body: JSON.stringify({ idToken, isCreator }),
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((r: Session) => {
      if (r.statusCode && r.statusCode !== 200) {
        throw new Error(r.message);
      }
      createCookie("accessToken", accessToken, EXPIRE_IN_A_YEAR);
      return r;
    });
  };

  const socialLoginCreateCreator = ({
    userId,
    username,
  }: {
    userId: string;
    username: string;
  }): Promise<any> => {
    const idToken = getCookie(document.cookie, "accessToken");
    return clientFetchService(SIXTY_URLS.API.AUTH.SOCIAL_CREATOR, {
      method: "POST",
      body: JSON.stringify({
        idToken: idToken,
        userId: userId,
        username: username,
      }),
    });
  };

  return {
    logIn,
    logOut,
    signUp,
    initiateRestore,
    completeRestore,
    socialLogin,
    socialLoginCreateCreator,
  };
};

export { useAuth };
