import { FunctionComponent, useContext } from "react";
import { Link } from "@/components/common/link";

// Context
import UserContext from "@/context/user";

// Utils
import { classNames } from "@/utils/classNames";
import { KUMU_URLS } from "@/utils/urls/kumu";

// Components
import { Main } from "@/components/common/main";
import { GetIcon } from "@/components/common/icon";

type Props = {
  showForm?: boolean;
  className?: string;
  srcFormImage: string;
  viewportHeight?: number;
  children: React.ReactNode;
  srcBackgroundBlurImage: string;
};

const KumuFlowLayout: FunctionComponent<Props> = ({
  children,
  showForm,
  className,
  srcFormImage,
  viewportHeight,
  srcBackgroundBlurImage,
}) => {
  const { user } = useContext(UserContext);

  return (
    <div
      className="fixed top-0 bottom-0 right-0 left-0 h-full bg-cover min-h-full flex flex-col w-full px-4 pt-6 lg:pl-[60px] lg:pr-[80px] 2xl:pt-[70px] overflow-y-auto bg-bottom bg-no-repeat"
      style={{ backgroundImage: `url(${srcBackgroundBlurImage})` }}
    >
      <Link
        href={!!user && KUMU_URLS.PAGES.B2C.FLOW.LOGIN}
        className="shrink-0 z-[999] mr-auto"
      >
        <GetIcon iconName="kumu-icon" className="fill-[#40B869]" />
      </Link>
      <Main
        className={classNames(
          "flex-1 flex flex-col lg:flex-row items-center justify-between max-w-[1150px] w-[100%] gap-x-10 z-50 mt-[55px] sm:mt-0 relative",
          className
        )}
        fluid
      >
        <div className="max-w-[200px] lg:max-w-[465px] w-full h-auto sm:hidden lg:block">
          <img src={srcFormImage} alt="form image" className="w-full h-full" />
        </div>
        <div
          className={classNames(
            "sm:m-auto absolute left-0 right-0 md:bottom-8 sm:relative sm:right-0 sm:left-0 sm:top-0 lg:m-0",
            showForm ? "top-8 bottom-8" : null,
            viewportHeight && viewportHeight < 690 ? "top-8" : null
          )}
        >
          {children}
        </div>
      </Main>
    </div>
  );
};

export default KumuFlowLayout;
