import Link from "next/link";
import { FunctionComponent } from "react";

// Components
import { Label } from "@/components/common/label";

// Utils
import { classNames } from "@/utils/classNames";

type Props = {
  link: string;
  label: string;
  linkLabel: string;
  className?: string;
};

const KumuEscapePath: FunctionComponent<Props> = ({
  label,
  link,
  className,
  linkLabel,
}) => {
  return (
    <section
      className={classNames(
        "w-full bg-white rounded-2xl px-4 pt-4 pb-6 flex flex-col justify-center items-center",
        className
      )}
    >
      <Label fontSize="base" textAlignment="center" textColor="[454545]">
        {label}
      </Label>
      <Link passHref href={link} legacyBehavior>
        <Label
          fontSize="base"
          fontWeight="semibold"
          textColor="[#212421]"
          className="underline cursor-pointer"
        >
          {linkLabel}
        </Label>
      </Link>
    </section>
  );
};

export default KumuEscapePath;
