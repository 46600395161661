import { FunctionComponent } from "react";
import { Label } from "../label";
import { CheckMarkIcon, GetIcon } from "../icon";

type Props = {
  message: string;
  backgroundColor?: string;
  messageTextColor?: string;
  withIcon?: boolean;
  status?: "Successful" | "Error"; //|"Error"|"Pending";
  className?: string;
  messageClassName?: string;
  withClose?: boolean;
  onClose?: (state?: boolean) => void;
};
const icons = {
  Successful: <CheckMarkIcon className="fill-[#03BD93] w-[13px] h-[13px]" />,
  //add status with icons when needed
  Error: (
    <GetIcon iconName="alert" className="w-4 h-4 text-white  stroke-white" />
  ),
  // Pending: <GetIcon iconName="pendingIcon" />,
};
export const Snackbar: FunctionComponent<Props> = ({
  message,
  className,
  messageClassName,
  backgroundColor = "#DEF0ED",
  messageTextColor = "[#014157]",
  withIcon = true,
  status = "Successful",
  withClose = false,
  onClose = () => {},
}) => {
  const currentIcon = icons[status];

  return (
    <div
      className={`min-w-[335px] flex justify-between items-center rounded-[10px] gap-[8px] py-[10px] bg-[${backgroundColor}] ${
        className ? className : ""
      }`}
    >
      <div className="flex justify-center items-center gap-[8px] pl-2.5">
        {withIcon && currentIcon}
        <Label
          textColor={messageTextColor}
          fontWeight="medium"
          className={messageClassName}
        >
          {message}
        </Label>
      </div>
      {withClose && (
        <div onClick={onClose as any}>
          <GetIcon
            iconName="close"
            className={`h-3 w-3 fill-white`}
            //   viewBox={active ? "0 0 24 24" : "0 0 0 0"}
          />
        </div>
      )}
    </div>
  );
};
