import React, { useEffect, useState } from "react";

type ViewportSize = {
  height: number;
  width: number;
};

const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState<ViewportSize>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const updateViewportSize = () => {
      setViewportSize({ height: window.innerHeight, width: window.innerWidth });
    };
    updateViewportSize();
    window.addEventListener("resize", updateViewportSize);

    return () => {
      window.removeEventListener("resize", updateViewportSize);
    };
  }, []);

  return { height: viewportSize.height, width: viewportSize.width };
};

export default useViewportSize;
