import { FunctionComponent } from "react";
// Utils
import { classNames } from "@/utils/classNames";

type Props = {
  containerClassName?: string;
  className?: string;
  fluid?: boolean;
  bgImageSrc?: string;
  blurImage?: boolean;
  styles?: any;
  children?: React.ReactNode;
};

const Main: FunctionComponent<Props> = ({
  children,
  className,
  fluid,
  styles,
}) => (
  <main
    className={classNames(className, `m-auto ${fluid ? "" : "max-w-[820px]"}`)}
    style={styles ?? {}}
  >
    {children}
  </main>
);

export const startFromBackgroundClasses =
  "mt-[120px] sm:mt-[200px] lg:mt-[200px]";

const MainWithBackground: FunctionComponent<Props> = ({
  children,
  containerClassName,
  className,
  bgImageSrc,
}) => {
  const containerClasses = {
    desktop: "relative w-full sm:min-h-screen sm:h-auto",
  };
  const imageClasses = {
    desktop:
      "absolute top-0 left-0 right-0 w-full h-[120px] sm:h-[200px] lg:h-[200px] object-cover",
  };
  return (
    <div className={classNames(containerClasses.desktop, containerClassName)}>
      <img
        className={classNames(imageClasses.desktop)}
        src={bgImageSrc || "/images/background-image-purple.png"}
      />
      <Main className={classNames(className, "relative")}>{children}</Main>
    </div>
  );
};

const MainWithFullBackground: FunctionComponent<Props> = ({
  children,
  containerClassName,
  className,
  bgImageSrc,
  blurImage = false,
}) => (
  <div
    className={classNames(
      "relative w-full sm:min-h-screen sm:h-auto",
      containerClassName
    )}
  >
    {blurImage ? (
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full backdrop-blur-[100px] bg-black/[0.1]"></div>
    ) : null}
    <img
      className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10"
      src={bgImageSrc || "/images/background-image-purple.png"}
    />
    <Main className={classNames(className, "relative")}>{children}</Main>
  </div>
);

export const toMaxW = "max-w-[820px] m-auto";

const MainWithDropShadow: FunctionComponent<Props> = ({
  children,
  className,
}) => {
  const containerClasses = {
    desktop: "sm:w-full sm:min-h-screen sm:h-auto sm:bg-[#FFFFFF]",
  };
  const shadowClasses = {
    desktop:
      "sm:opacity-50 sm:absolute sm:h-[369px] sm:w-full sm:bg-gradient-to-b sm:from-[#CCE9E2] sm:to-[#FFFFFF]",
  };
  return (
    <div className={classNames(containerClasses.desktop)}>
      <div className={classNames(shadowClasses.desktop)}></div>
      <Main className={classNames(className, "relative")}>{children}</Main>
    </div>
  );
};

const MainWithDropShadowMobile: FunctionComponent<Props> = ({
  children,
  className,
}) => {
  const containerClasses = {
    mobile:
      "tablet:w-full tablet:min-h-screen tablet:h-auto tablet:bg-light-green",
  };
  const shadowClasses = {
    mobile:
      "tablet:absolute tablet:h-[315px] tablet:w-full tablet:bg-gradient-to-b tablet:from-white tablet:to-light-green",
  };
  return (
    <div className={classNames(containerClasses.mobile)}>
      <div className={classNames(shadowClasses.mobile)}></div>
      <Main className={classNames(className, "relative")}>{children}</Main>
    </div>
  );
};

export {
  Main,
  MainWithDropShadow,
  MainWithBackground,
  MainWithFullBackground,
  MainWithDropShadowMobile,
};
