import { FunctionComponent } from "react";

// Components
import { Label } from "@/components/common/label";

// Utils
import { classNames } from "@/utils/classNames";

type Props = {
  title: string;
  className?: string;
  titleClassName?: string;
  description?: string;
  descriptionClassName?: string;
  children: React.ReactNode;
};

const FlowFormsLayout: FunctionComponent<Props> = ({
  title,
  children,
  className,
  titleClassName,
  descriptionClassName,
  description,
}) => {
  return (
    <section
      className={classNames(
        "bg-white rounded-xl sm:max-w-[520px] w-full sm:min-w-[450px] py-10",
        className
      )}
    >
      <Label
        fontSize="26px"
        textAlignment="left"
        fontWeight="semibold"
        textColor="[#212421]"
        className={classNames(
          "pl-10 text-[20px] lg:text-[26px]",
          titleClassName
        )}
      >
        {title}
      </Label>
      {description && (
        <Label
          fontSize="base"
          className={classNames("mt-2 px-10", descriptionClassName)}
          textAlignment="left"
          textColor="[#454545]"
        >
          {description}
        </Label>
      )}
      {children}
    </section>
  );
};

export default FlowFormsLayout;
