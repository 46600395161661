// Convert Pascal and camel case to kebab string
const toKebab = (str: string): string =>
  str.replace(
    // Match uppercase letters that are followed by lowercase
    /[A-Z]+(?![a-z])|[A-Z]/g,
    // Separate matched strings by "-" and transform to lowercase
    (capital, index) => (index ? "-" : "") + capital.toLowerCase()
  );

export const getTestIdGenerator = (componentName: string) => {
  return (elementName: string): string => toKebab(componentName + elementName);
};
